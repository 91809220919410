<template>
  <template v-if="show">
    <slot name="blocks">
      <Block
        v-for="(block, n) in blocks"
        :id="`${region}-${n}`"
        :key="n"
        :type="isUzi(block?.type) ? 'UziQuery' : block?.type"
        :title="block?.title"
        :props="block?.props"
        v-bind="$attrs"
        class="blockClass"
        @nodata="nodata"
      ></Block>
    </slot>
  </template>
</template>

<script setup>
import { isUzi } from "@/lib/tools.js";
const emit = defineEmits(["noregion"]);
const page = inject("page");

const props = defineProps({
  region: { type: String, required: true, default: "" },
  blocks: { type: Array, required: true, default: () => [] },
});

const show = ref(true);
const { blocks } = toRefs(props);
let count = unref(blocks).length;
const nodata = () => {
  count--;
  // console.log("nodata on ", props.region);
  if (count === 0) {
    show.value = false;
    unref(page).removeRegion(props.region);
    // console.log("boom", unref(page));
    emit("noregion");
  }
};
</script>
